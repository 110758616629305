<template>
  <div v-if="loading">Cargando...</div>
  <div v-else class="test-container p-1">
    <h3 v-if="questionTest">{{ questionTest.title }}</h3>
    <h5 v-if="questionTest">{{ questionTest.description }}</h5>
    <div v-if="questionTest" class="time-cell">
      <template v-if="evaluation.finished">
        <div class="time-label left-time-label">Evaluación finalizada</div>
      </template>
      <template v-else>
        <div class="time-label start-time-label">Tiempo Restante :</div>
        <div class="time-card">
          <Timer :end_date="this.evaluation.end_date" />
        </div>
      </template>
    </div>

    <h5 v-if="evaluationQuestions.length == 0">
      El examen no tiene preguntas confeccionadas.
    </h5>
    <template v-for="question in evaluationQuestions">
      <template v-if="question.question_type <= 2">
        <AnswerRedactionQuestion
          :key="question.id"
          :Question="question"
          :student_id="student_id"
          :is_selected="question.id == selected_question"
          @selected="slotSelectedQuestion"
        ></AnswerRedactionQuestion>
      </template>
      <template v-else-if="question.question_type == 3">
        <AnswerSelectQuestion
          :key="question.id"
          :Question="question"
          :student_id="student_id"
          :is_selected="question.id == selected_question"
          @selected="slotSelectedQuestion"
        ></AnswerSelectQuestion>
      </template>
    </template>
    <b-button v-if="!evaluation.finished" @click="click" class="mb-2"
      >Guardar</b-button
    >
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "AnswerTestContainer",
  components: {
    AnswerRedactionQuestion: () => import("./AnswerRedactionQuestion"),
    AnswerSelectQuestion: () => import("./AnswerSelectQuestion"),
    Timer: () => import("../../../reusable/Timer.vue"),
  },
  props: {
    question_test_id: {
      type: Number,
      required: true,
    },
    student_id: {
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_question: null,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      questionTests: names.QUESTION_TESTS,
      allQuestionOptions: names.MULTIPLE_CHOICE_QUESTION_OPTIONS,
      evaluations: names.EVALUATIONS,
      questions: names.QUESTIONS,
    }),
    questionTest() {
      return this.questionTests.find((x) => x.id == this.question_test_id);
    },
    evaluation() {
      if (!this.questionTest) return null;
      let evaluation = this.evaluations.find(
        (x) => x.id == this.questionTest.evaluation
      );
      return evaluation
        ? evaluation
        : this.$store.dispatch(
            names.FETCH_EVALUATION,
            this.questionTest.evaluatio
          );
    },
    evaluationQuestions() {
      let list = this.questions.filter(
        (x) => x.question_test == this.question_test_id
      );
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
  },
  methods: {
    click: function () {
      this.$emit("update", true);
    },
    slotSelectedQuestion(question_id) {
      this.selected_question = question_id;
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_QUESTIONS, this.question_test_id)
      .then(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.test-container {
  background-color: var(--primary-color);
  border-radius: 5px;
}
.time-label {
  width: fit-content;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  margin-right: 7px;
}
.left-time-label {
  background-color: rgb(235, 70, 70);
}
.start-time-label {
  background-color: green;
}
.time-card {
  width: fit-content;
  margin-right: 10px;
}
.time-cell {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
